<template>
  <b-card title="Familjemedlemmar" style="width: 100%">

    <b-table responsive small :fields="fields" :items="family" >

    </b-table>

  </b-card>

</template>

<script>
import axios from 'axios';
import RegisterFamilyForm from '@/view/components/RegisterFamilyForm';

export default {
  name: 'FamilyTable',
  components: {
    RegisterFamilyForm
  },
  props: ['member_id','family'],
  computed: {

  },
  data() {
    return {
      idCounter: 0,
      fields: [
        { key: 'firstname', label: this.$t('MEMBER.FIRSTNAME') },
        { key: 'lastname', label: this.$t('MEMBER.LASTNAME') },
        { key: 'email', label: this.$t('MEMBER.EMAIL') },
        { key: 'phone', label: this.$t('MEMBER.MOBILE') },
        { key: 'personnr', label: this.$t('MEMBER.PERSONNR') },
        // { key: 'head', label: 'Huvudmedlem' },
        // { key: 'ca', label: 'Organisation' },
        { key: 'action', label: '' }
      ],
      items: [],
      data: null,
      testData: [
        {
          firstname: 'test1',
          lastname: 'lastname',
          email: 'email@email.com',
          phone: '08234234',
          id: 'aaa',
          personnr: '823211-1231',
          head: false
        }
      ]
    };
  },
  watch: {

  },
  mounted() {},
  methods: {
    getData() {
      return this.family;
    },

    rowClass(item, type) {
      if (item && type === 'row') {
        if (item.head) {
          return 'font-weight-bolder';
        }

        if (item.is_created) {
          return 'text-success';
        }
      }

      return null;
    },

  }
};
</script>
<style scoped>
:deep .table > tbody > tr > td {
  vertical-align: middle;
}
#app .container {
  background-color: #293039;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 100%;
}
#bar {
  flex: 1;
  background-color: #293039;
  color: white;
  padding: 10px;
  display: flex;
  max-height: 65px;
}
#bar h1 {
  flex: 1;
  font-size: 16px;
  text-align: left;
}
#bar button {
  min-width: 80px;
  cursor: pointer;
}
</style>
